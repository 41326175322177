<template>
  <el-dialog
    :model-value="modelValue"
    title="详情"
    @close="closed"
    width="680px"
  >
    <el-descriptions title="" :column="2" style="padding-top: 0px!important;">
      <el-descriptions-item label="操作人:"
        >{{ data.nickname }}({{ data.username }})</el-descriptions-item
      >
      <el-descriptions-item label="IP地址">{{ data.ip }}</el-descriptions-item>
      <el-descriptions-item label="操作模块:">{{
        data.module
      }}</el-descriptions-item>
      <el-descriptions-item label="操作功能:">{{
        data.description
      }}</el-descriptions-item>
      <el-descriptions-item label="操作时间:">{{
        data.createTime
      }}</el-descriptions-item>
      <el-descriptions-item label="请求耗时:"
        >{{ data.spendTime / 1000 }}s</el-descriptions-item
      >
      <el-descriptions-item label="请求方式:">{{
        data.requestMethod
      }}</el-descriptions-item>
      <el-descriptions-item label="请求状态:">
        <el-tag size="small" type="success" v-if="data.status == 0"
          >成功
        </el-tag>
        <el-tag size="small" type="danger" v-if="data.status == 1">异常</el-tag>
      </el-descriptions-item>
    </el-descriptions>

    <el-divider></el-divider>

    <el-descriptions title="" :column="1">
      <el-descriptions-item label="操作系统:">{{
        data.os
      }}</el-descriptions-item>
      <el-descriptions-item label="浏览器:">{{
        data.browser
      }}</el-descriptions-item>
      <el-descriptions-item label="设备名称:">{{
        data.device
      }}</el-descriptions-item>
      <el-descriptions-item label="请求地址:">{{
        data.url
      }}</el-descriptions-item>
      <el-descriptions-item label="调用方法:">{{
        data.method
      }}</el-descriptions-item>
      <el-descriptions-item label="请求参数:">{{
        data.params
      }}</el-descriptions-item>
      <el-descriptions-item label="返回结果:">{{
        data.result
      }}</el-descriptions-item>
    </el-descriptions>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { getSysOperationRecordById } from '@/api/sys-operation-record'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  id: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'id'])

const closed = () => {
  emits('update:modelValue', false)
}

const data = ref({})
const getOperationRecordData = async () => {
  data.value = await getSysOperationRecordById(props.id)
}
getOperationRecordData()
</script>

<style lang="scss">
.el-descriptions__content {
  color: #999999;
}
</style>
