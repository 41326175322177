<template>
  <div>
    <el-card>
      <div class="header">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="账号">
            <el-input
              v-model="queryForm.username"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="昵称">
            <el-input
              v-model="queryForm.nickname"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="操作模块">
            <el-input
              v-model="queryForm.module"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button size="small" type="primary" @click="listToExcel"
            >导出
          </el-button>
          <!--          <el-button size="small" type="danger">删除</el-button>-->
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column
            property="username"
            label="系统账号"
            min-width="100"
          />
          <el-table-column property="nickname" label="用户昵称" />
          <el-table-column property="module" label="操作模块" />
          <el-table-column property="description" label="操作功能" />
          <el-table-column
            property="url"
            label="请求地址"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            property="requestMethod"
            label="请求方式"
            width="80"
          />
          <el-table-column property="status" label="状态" width="80">
            <template #default="scope">
              <el-tag type="success" v-if="scope.row.status == 0">成功 </el-tag>
              <el-tag type="danger" v-if="scope.row.status != 0"
                >异常</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column property="spendTime" label="耗时" width="80">
            <template #default="scope">
              {{ scope.row.spendTime / 1000 }}s
            </template>
          </el-table-column>
          <el-table-column
            property="createTime"
            sortable="custom"
            label="操作时间"
            width="150"
          />
          <el-table-column fixed="right" label="操作" width="50">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="showDetailClick(scope.row)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <show-operation-record
      v-if="showDialogVisible"
      v-model="showDialogVisible"
      :id="selectItemId"
    >
    </show-operation-record>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getSysOperationRecordPage } from '@/api/sys-operation-record'
import { LOGIN_RECORD } from './Export2ExcelConstants'
import showOperationRecord from './components/ShowOperationRecord'
const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  username: '',
  nickname: '',
  module: ''
})

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getSysOperationRecordPage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 选择要操作的列表id
 */
const selectItemId = ref(0)

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 根据创建时间排序
 */
const handleSortChange = val => {
  console.log(val.order)
  if (val.order === 'ascending') {
    queryForm.value.order = 'asc'
  } else {
    queryForm.value.order = 'desc'
  }
  getListData()
}

/**
 * 导出为excel
 */
const listToExcel = async () => {
  // 导入工具包
  const excel = await import('@/utils/Export2Excel')
  const data = formatJson(LOGIN_RECORD, tableData.value)
  excel.export_json_to_excel({
    // excel 表头
    header: Object.keys(LOGIN_RECORD),
    // excel 数据（二维数组结构）
    data,
    // 文件名称
    filename: '操作日志',
    // 是否自动列宽
    autoWidth: true,
    // 文件类型
    bookType: 'xlsx'
  })
}

// 该方法负责将数组转化成二维数组
const formatJson = (headers, rows) => {
  // 首先遍历数组
  // [{ username: '张三'},{},{}]  => [[’张三'],[],[]]
  return rows.map(item => {
    return Object.keys(headers).map(key => {
      // 操作类型处理
      if (headers[key] === 'status') {
        switch (item[headers[key]]) {
          case 0:
            return '成功'
          case 1:
            return '异常'
        }
      }
      return item[headers[key]]
    })
  })
}

const showDialogVisible = ref(false)
const showDetailClick = row => {
  selectItemId.value = row.id
  showDialogVisible.value = true
}
</script>

<style scoped></style>
