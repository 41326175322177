import request from '../utils/request'

/**
 * 获取操作日志分页数据
 * @param data
 * @returns {AxiosPromise}
 */
export const getSysOperationRecordPage = data => {
  return request({
    url: '/operationRecord/page',
    params: data
  })
}

/**
 * 查询指定id操作日志
 * @param id
 * @returns {AxiosPromise}
 */
export const getSysOperationRecordById = id => {
  return request({
    url: `/operationRecord/${id}`
  })
}
